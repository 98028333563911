
  .form-section {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
    /* padding: 80px 0px 0px 0px; */
  }

  /* .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  } */

  .login-area {
    background: #fff;
    margin: 0px auto;
    width: 400px;
    height: auto;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  }
  .login-area h2 {
    font-size: 22px;
    font-weight: 600 !important;
    color: #202123 !important;
  }
  .login-area a {
    font-size: 14px;
    font-weight: 400;
    /* color: #555 !important; */
  }
  .login-area span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .login-area .form-area {
    /* margin-top: 25px; */
  }

  .form-area .input-box {
    box-sizing: border-box;
    padding: 10px;
    background: #fff;
    border: 2px solid #ddd;
    width: 100%;
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 0 6px 6px -6px #ddd;
  }
  label {
    display: block;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 12px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }

  .form-control {
    padding: 5px!important;
   
  }

  .btn{
    cursor: pointer;
   padding: 10px 20px;
   font-size: 15px;
   text-align: center;
   /* display: block; */
   border-radius: 10px;
   outline: none;
   width: auto;
   margin-top: 16px;
   font-weight: 600;
  }


  .submit-btn {
    color: #fff;
    background: #202123;
    display: block;
    border: none;
    display: block;
    margin: 8px auto;
    box-shadow: 0 6px 6px -6px #ddd;
  }
  
 /* .submit-btn:hover {
    background: #fff;
    border: 1px solid #144787;
    color: #144787;

  } */

  .forgot-btn{
    color: #fff;
    background: #202123;
    border: none;
  }

  .cancel-btn{
    background: #fff;
   color:  #202123;
   border: 1px solid #202123;
   box-shadow: 0 6px 6px -6px #202123;
  }

  /* .cancel-btn:hover{
    background:  #15509a;
    color: #fff;
  } */
  
  .error {
    color: #f00;
    font-size: 10px;
    margin-bottom: 2px;

  }


  @media only screen and (max-width: 600px) {
    .login-area {
     
      width: 350px;
    
    }
}