/* Typing effect styles */
.typing-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
    animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
  }
  
  .show-text {
    width: 100%; /* Will reveal the entire text */
  }
  
  /* Animation for typing */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  /* Animation for the blinking caret */
  @keyframes blink-caret {
    from, to {
      border-right-color: transparent;
    }
    50% {
      border-right-color: black;
    }
  }
  
  /* Scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(79, 79, 79);
    border-radius: 8px;
  }
  
  /* LMS text styles */
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  #policy {
    font-size: 46px;
    text-transform: uppercase;
    font-family: 'Gambetta', serif;
    letter-spacing: -3px;
    transition: 700ms ease;
    font-variation-settings: "wght" 311;
    margin-bottom: 0.8rem;
    color: PaleGoldenRod;
    outline: none;
    text-align: center;
  }
  
  #policy:hover {
    font-variation-settings: "wght" 582;
    letter-spacing: 1px;
  }

  @keyframes pulsate {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 0 10px 10px rgba(59, 130, 246, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
    }
  }
  
  .pulsating {
    animation: pulsate 1s infinite;
  }
  